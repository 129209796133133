'use strict';

jQuery(function($){
	$('.site-header').each(function(index, node){
		new SiteHeader(node);
	})
	$('.site-header .navigation').each(function(index, node){
		new MainMenu(node);
	})
	$('.site-submenu').each(function(index, node){
		new Menu(node);
	})
});
