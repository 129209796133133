'use strict';

function Menu($node, conf){
	this.$node = null;
	this.init($node, conf);
};
Menu.prototype.init = function($node, conf){
	this.initNode($node);
	this.initEvents();
};
Menu.prototype.initNode = function($node){
	this.$node = $($node);
};
Menu.prototype.initEvents = function(){
	$(window)
		.on('scroll resize load', $.proxy(this.onChange, this))
	;

	$(document)
		.on('click', $.proxy(this.onClickDocument, this))
	;

	this.$node
		.on('click', '.toggle', $.proxy(this.onClick, this))
	;
};
Menu.prototype.layout = function(){
	var scrollTop = $(window).scrollTop();
	if (110 < scrollTop) {
		this.$node.addClass('fixed');
	} else {
		this.$node.removeClass('fixed');
	}
};
Menu.prototype.onChange = function(){
	this.layout();
};
Menu.prototype.onClick = function(e){
	e.preventDefault();
	this.$node.toggleClass('open');
};
Menu.prototype.onClickDocument = function(e){
	if (!$(e.target).closest(this.$node).length) {
		this.$node.removeClass('open');
	}
};
