'use strict';

function SiteHeader($node){
	this.$node = null;
	this.height = 110;
	this.init($node);
};
SiteHeader.prototype.init = function($node){
	this.initNode($node);
	this.initEvents();
	this.checkScroll();
};
SiteHeader.prototype.initNode = function($node){
	this.$node = $($node);
};
SiteHeader.prototype.initEvents = function(){
	$(window)
		.on('scroll resize load', $.proxy(this.onScroll, this))
	;
};
SiteHeader.prototype.checkScroll = function(){
	var scrollTop = $(window).scrollTop();
	if (scrollTop > this.height) {
		this.$node.removeClass('unscrolled');
		this.$node.addClass('scrolled');
	} else {
		this.$node.addClass('unscrolled');
		this.$node.removeClass('scrolled');
	}
};
SiteHeader.prototype.onScroll = function(e){
	this.checkScroll();
};
