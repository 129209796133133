'use strict';

function MainMenu($node, conf){
	this.$node = null;
	this.init($node, conf);
};
MainMenu.prototype.init = function($node, conf){
	this.initNode($node);
	this.initEvents();
	this.setCurrent();
};
MainMenu.prototype.initNode = function($node){
	this.$node = $($node);
};
MainMenu.prototype.initEvents = function(){
	this.$node
		.on('click', '.toggle', $.proxy(this.onClick, this))
	;
	$(document)
		.on('click', $.proxy(this.onClickDocument, this))
	;
};
MainMenu.prototype.setCurrent = function(){
	$('body.cat-about')
		.find(this.$node.find('[href^="/about/"]'))
		.addClass('current')
	;
	$('body.cat-business')
		.find(this.$node.find('[href^="/business/"]'))
		.addClass('current')
	;
	$('body.cat-corporate')
		.find(this.$node.find('[href^="/corporate/"]'))
		.addClass('current')
	;
	$('body.cat-recruit')
		.find(this.$node.find('[href^="/recruit/"]'))
		.addClass('current')
	;
};
MainMenu.prototype.onClick = function(e){
	e.preventDefault();
	if (this.$node.is('.open')) {
		this.$node.removeClass('open');
		this.$node.addClass('close');
	} else {
		this.$node.addClass('open');
		this.$node.removeClass('close');
	}
};
MainMenu.prototype.onClickDocument = function(e){
	if (!$(e.target).closest(this.$node).length) {
		this.$node.removeClass('open');
		this.$node.addClass('close');
	}
};
